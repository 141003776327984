@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,800;1,400;1,800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

u {
  display: inline;
}

/* Landing Section */
.yellowGrid {
  display: grid;
}

.yellowGrid > div:last-child {
  background-color: #98bddc;
  box-shadow: 12px 12px black;
  padding: 16px 24px;
}

/* SVG mask for Profile and SVG stroke*/
.profileContainer {
  position: relative;
  max-width: 384px;
}

.profileContainer svg {
  position: absolute;
  top: -20px;
  bottom: 8px;
  left: -20px;
  right: 12px;
  z-index: 1;
}

.profileContainer img {
  -webkit-mask-image: url("data:image/svg+xml,<svg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'><path d='M70.2,-22.1C79.4,5.5,67.3,40.8,44.4,56.3C21.4,71.8,-12.3,67.6,-35.3,50.4C-58.4,33.3,-70.7,3.1,-63.1,-22.3C-55.4,-47.8,-27.7,-68.6,1.4,-69.1C30.5,-69.5,61,-49.6,70.2,-22.1Z' transform='translate(100 100)'/></svg>");
  mask-image: url("data:image/svg+xml,<svg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'><path d='M70.2,-22.1C79.4,5.5,67.3,40.8,44.4,56.3C21.4,71.8,-12.3,67.6,-35.3,50.4C-58.4,33.3,-70.7,3.1,-63.1,-22.3C-55.4,-47.8,-27.7,-68.6,1.4,-69.1C30.5,-69.5,61,-49.6,70.2,-22.1Z' transform='translate(100 100)'/></svg>");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  padding: 1rem;
  max-width: 384px;
  width: 80vw;
  z-index: 0;
}

/* Research Section */
.universalGrid {
  display: grid;
}

/* 2 card-like box */
.universalGrid > div {
  text-align: center;
  position: relative;
  margin: 2rem;
  max-width: 28rem;
}

.universalGrid > div:nth-of-type(2) > div {
  position: absolute;
  top: -1.25rem;
  left: -1rem;
  width: 105%;
  height: 110%;
  z-index: 0;
  transform: rotate(-13deg);
}

.pinkGrid > div:nth-of-type(2) p {
  background-color: #FAFAFA;
  font-family: "Nunito Sans";
  font-size: 1.25rem;
  font-weight: 800;
  padding: 0.75rem;
  margin: 0.75rem;
  position: inherit;
  z-index: 1;
}

.blueGrid > div:nth-of-type(n+2) {
  box-shadow: inset 2px 2px 5px rgba(255, 255, 255, 0.1), 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  background-color: rgba(179, 189, 202, 0.1);
  font-family: "Ubuntu";
  font-size: 1rem;
  font-weight: 100;
  border-radius: 1rem;
  padding: 0.75rem;
  margin: 0.75rem;
  position: inherit;
  z-index: 1;
  color: #f8fcff;
}

.blueGrid > div:nth-of-type(n+2) li div {
  display: flex;
  justify-content: center;
}

.blueGrid div:nth-of-type(n+2) li div a {
  margin-left: 0.5rem;
  color: #ebd676;
}

.blueGrid div:nth-of-type(2) li div {
  font-size: 1rem;
  color: white;
  line-height: 1.6;
  text-align: justify;
  text-indent: 20px;
}

.blueGrid .projectLink {
  font-weight: 600;
  color: #66C0E2;
}

.blueGrid .projectLink:hover {
  color: #d5b733;
}

.pinkGrid > div:nth-of-type(2) > div:first-of-type {
  border-right: 1rem dashed #33bad5;
  border-left: 1rem dashed #33bad5;
  z-index: 2;
}

.pinkGrid > div:nth-of-type(2) > div:last-of-type {
  border-top: 1rem dashed #33bad5;
  border-bottom: 1rem dashed #33bad5;
  z-index: 0;
}

.pinkGrid > div:nth-of-type(3) > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 102%;
  z-index: 0;
  transform: rotate(12deg);
}

.pinkGrid > div:nth-of-type(3) > div:first-of-type {
  border-bottom: 0.75rem dashed #33bad5;
  border-top: 0.75rem dashed #33bad5;
  border-right: 0.75rem dashed #33bad5;
  z-index: 2;
}

.pinkGrid > div:nth-of-type(3) > div:last-of-type {
  border-left: 0.75rem dashed #33bad5;
  z-index: 0;
}

.pinkGrid ul {
  padding: 0.75rem;
  margin: 0.75rem;
  background-color: #FAFAFA;
  position: inherit;
  z-index: 1;
}

.universalGrid li {
  padding: 0.5rem 0;
}

.pinkGrid h1 {
  color: #FAFAFA;
  text-shadow: 0.15rem 0.15rem #33d59f;
}

.blueGrid h1 {
  color: #FAFAFA;
  text-shadow: 0.15rem 0.15rem #d5b733;
}

.greenGrid h1 {
  color: #FAFAFA;
  text-shadow: 0.15rem 0.15rem #9f33d5;
}

.greenGrid h2 {
  margin: 1rem;
}

.pinkGrid span {
  display: block;
  color: #b12453;
}

.pinkGrid svg {
  color: #FDBB2D;
}

.pinkGrid button {
  border-top: 0.25rem solid #33d59f;
  border-left: 0.25rem solid #33d59f;
  box-shadow: 0.25rem 0.25rem #9f33d5;
  transform: rotate(12deg);
  transition: 0.05s;
}

.pinkGrid button:hover {
  border-top: 0;
  border-left: 0;
  border-bottom: 0.25rem solid #69d533;
  border-right: 0.25rem solid #69d533;
  box-shadow: 0.25rem 0.25rem rgba(0, 0, 0, 0), -0.25rem -0.25rem #9f33d5;
  transition: 0.05s;
}

.greenGrid div:nth-of-type(n+2) {
  font-size: 1rem;
  color: white;
  line-height: 1.6;
  text-align: justify;
  text-indent: 20px;
}

.greenGrid > div:nth-of-type(n+2) {
  box-shadow: inset 2px 2px 5px rgba(255, 255, 255, 0.1), 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 23, 52, 0.2);
  font-family: "Ubuntu";
  font-size: 1rem;
  font-weight: 100;
  border-radius: 1rem;
  padding: 0.75rem;
  margin: 0.75rem;
  position: inherit;
  z-index: 1;
  color: #f8fcff;
}

.greenGrid a {
  color: #aed581;
  font-weight: 500;
}

.greenGrid tr > td:first-of-type {
  color: #aed581;
}

.greenGrid a:hover {
  color: #e0b8f4;
  font-weight: 800;
}

@media screen and (min-width: 992px) {
  nav {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .yellowGrid {
    grid-template-columns: 1fr 8rem 1fr;
  }
  .yellowGrid > div:last-child {
    grid-column-start: 3;
  }
  .profileContainer svg {
    animation: svg-spin infinite 30s linear;
  }
  .universalGrid {
    grid-template-columns: 1fr 1fr 8rem;
    column-gap: 6rem;
    margin: 4rem;
  }
  .universalGrid > div:first-of-type {
    writing-mode: vertical-rl;
    grid-column-start: 3;
    grid-row: 1;
  }
  .universalGrid > div:nth-of-type(2n) {
    grid-column-start: 2;
  }
  .universalGrid > div:nth-of-type(2) {
    grid-row: 1;
  }
  .universalGrid > div:nth-of-type(3) {
    grid-column-start: 1;
    grid-row: 1/3;
  }
  .pinkGrid button {
    transform: rotate(-13deg);
    margin-top: -4rem;
    float: right;
  }
}
@keyframes svg-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}